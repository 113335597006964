<template>
  <div
    id="navBar"
    class="selections frame-b"
  >
    <h1
      id="navHomeLink"
      aria-label="foggyrealm.com home"
      tabindex="-1"
    >
      <router-link :to="{
        name: 'ladRoutedView',
        params: {
          fogCat: 'home'
        }
      }">
        foggyrealm.com
      </router-link>
    </h1>
    <nav id="navHamburger">
      <button
        id="hamburgerBtn"
        class="icon-and-label-button"
        aria-label="Navigation Tabs"
        aria-expanded="false"
        aria-controls="navTabs"
        @click="showHideNavMenu()"
        @keydown="showHideNavMenu()"
      >
        <span class="foggy-icon show-hide-nav"/>
        <span class="label">Menu</span>
      </button>
    </nav>
    <nav>
      <ul
        id="navTabs"
        :class="{ 'isMenu': uiMenuVisible === true }"
        class="bg1"
        role="tablist"
      >
        <li
          v-for="item in mapsData.nav"
          :key="item.abbrev"
          class="frame-r"
        >
          <router-link
            :to="{ name: 'ladRoutedView', params: { fogCat: item.abbrev }}"
            role="tab"
            :aria-label="item.title"
            @click="showHideNavMenu(false)"
            @keydown="showHideNavMenu(false)"
          >
            {{ item.title }}
          </router-link>
        </li>
      </ul>
    </nav>
    <div
      id="navPlayerButton"
      class="flex1"
    >
      <show-hide-player-button />
    </div>
  </div>
</template>

<script>
import mixinFunctions from '@/mixins/functions';
import showHidePlayerButton from '../audioPlaylistPlayer/buttons/showHidePlayerButton.vue';

export default {
  components: {
    showHidePlayerButton,
  },
  mixins: [
    mixinFunctions,
  ],
  data() {
    return {};
  },
  computed: {
    mapsData() {
      return this.$store.getters.mapsJSON;
    },
    uiMenuVisible() {
      return this.$store.getters.ui.menuVisible;
    },
    uiPlayerVisible() {
      return this.$store.getters.ui.playerVisible;
    },
  },
  methods: {
    showHideNavMenu(tf = '') {
      let dtf = tf;
      if (dtf === '') {
        dtf = !this.uiMenuVisible;
      }
      document.getElementById('hamburgerBtn').ariaExpanded = dtf;
      this.showHideMenu(dtf);
      this.uiForCatAbbrev();
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

  #navBar {
    display: flex;
    align-items: center;

    #navHomeLink {
      a {
        display: inline-block;
        padding: 1.4rem;
      }

      &.router-link-active {
        padding: 1.4rem 2rem 1.5rem;
        font-weight: bold;

        &:focus {
          outline-offset: 0;
          outline-width: 3px;
          margin: 3px;
          padding: 1.1rem 1.7rem 1.2rem;
        }
      }
    }

    #navPlayerButton {
      margin: 0.5rem 1rem;
      text-align: right;
    }

    #navHamburger {
      display: none;
      margin: 1rem;
    }

    #navTabs {
      flex: 1;

      li {
        display: inline-block;

        a {
          text-decoration: none;
          display: inline-block;
          padding: 1.4rem;

          &:focus {
            outline-offset: 0;
            outline-width: 3px;
            margin: 3px;
            padding: 1.1rem;
          }
        }
      }
      &.isMenu {
        z-index: 6;
        display: block;

        li {
          display: flex;

          a {
            flex: 1;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    #navBar {
      #navTabs {
        position: absolute;
        right: 0;
        top: 4.5rem;
        display: none;
      }

      #navHomeLink {
        flex: 1;
      }
      #navPlayerButton {
        order: 2;
      }
      #navHamburger {
        display: block;
        order: 3;
        margin: 0.6rem 1rem;
      }
    }
  }
</style>
