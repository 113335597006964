<template>
  <div
    id="detailsSection"
    class="flex1 flexV"
  >
    <!--
    DETAILS ({{ routerCat }}/{{ routerAbbrev }})
    {{ JSON.stringify(detailsData) }}
  -->
    <div
      id="detailsBreadcrumb"
    >
      <h2
        class="bc-selected-cat"
        tabindex="-1"
        @click="backToList()"
        @keydown="backToList()"
      >
        {{ catTitle }}
      </h2>
      <h3
        v-show="detailsTitle !== ''"
        class="bc-selected-abbrev"
        tabindex="-1"
        v-html="detailsTitle"
      @click="backToDetails()"
        @keydown="backToDetails()"
      />
    </div>
    <div
      :cat="fogCat"
      :abbrev="abbrev"
    />
    <template v-if="detailsData.vue === undefined">
      <div class="centered-vh flex1">
        <div class="instruction-text">
          No result found for "{{ abbrev }}".
        </div>
      </div>
    </template>
    <template v-else>
      <div class="frame-b">
        <h3
          id="detailsTitle"
          tabindex="-1"
        >
          <span
            class="details-title"
            v-html="detailsTitle"
          />
        </h3>
      </div>
      <div
        v-if="routerAbbrev !== ''"
        id="detailsContent"
        class="flex1"
      >
        <div
          v-if="detailsPhoto !== ''"
          class="photo"
        >
          <load-image
            :filename="detailsPhoto"
            :title="detailsTitle"
          />
        </div>
        <div
          v-for="(item, index) in foggydata[routerCat]"
          :key="index"
          :class="item.index"
        >
          <template v-if="showSection(detailsData.vue, item.index) === true">
            <template v-if="item.kind === 'htmlString'">
              <template v-if="detailsData.vue[item.index] !== ''">
                <section-html-string
                  :label="item.label"
                  :html="detailsData.vue[item.index]"
                />
              </template>
            </template>

            <template v-else-if="item.kind === 'textArray'">
              <template v-if="detailsData.vue[item.index].length > 0">
                <section-html-array
                  :label="item.label"
                  :html="detailsData.vue[item.index]"
                />
              </template>
            </template>

            <template v-else-if="item.kind === 'parentChildren'">
              <template
                v-if="checkForDataPC( detailsData.vue, detailsData.children, item.index ) === true"
              >
                <section-parent-children
                  :label="item.label"
                  :main-content="detailsData.vue"
                  :subsection-content="detailsData.children"
                  :index="item.index"
                />
              </template>
            </template>

            <template v-else-if="item.kind === 'audioTable' && showTracks === true">
              <section-table
                :label="`${item.label} (${detailsData.vue.audio_tracks.length} Total)`"
                :rows="detailsData.vue.audio_tracks"
                :dl="detailsData.vue.download"
                :playlist="getDetailsData('playlist')"
              />
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import foggydata from '@/store/json/foggydata.json';
import mixinFunctions from '@/mixins/functions';
import LoadImage from '@/components/helpers/LoadImage.vue';
import SectionHtmlString from '@/components/helpers/SectionHtmlString.vue';
import SectionHtmlArray from '@/components/helpers/SectionHtmlArray.vue';
import SectionParentChildren from '@/components/helpers/SectionParentChildren.vue';
import SectionTable from '@/components/helpers/SectionTable.vue';

export default {
  components: {
    LoadImage,
    SectionTable,
    SectionHtmlString,
    SectionHtmlArray,
    SectionParentChildren,
  },
  mixins: [
    mixinFunctions,
  ],
  props: {
    fogCat: {
      type: String,
      default: '',
    },
    abbrev: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      foggydata,
    };
  },
  computed: {
    dClass() {
      return `flex1 flexV cat_${this.routerCat}`;
    },
    detailsData() {
      return this.$store.getters.detailsJSON;
    },
    detailsTitle() {
      let r = '&nbsp;';

      if (this.detailsData.vue !== undefined) {
        if (this.detailsData.vue.title !== undefined) {
          r = this.detailsData.vue.title;
        }
      }

      return r;
    },
    detailsPhoto() {
      let r = '';

      if (this.detailsData.vue !== undefined) {
        if (this.detailsData.vue.photo !== undefined) {
          r = this.detailsData.vue.photo;
        }
      }

      return r;
    },
    showTracks() {
      let r = false;
      if (this.getDetailsData('playlist').length > 0) {
        r = true;
      }

      return r;
    },
  },
  updated() {
    const panel = document.getElementById('detailsSection');
    panel.scrollTop = 0;
  },

  methods: {
    backToList() {
      this.storeUpdateUI('detailsVisible', false);
    },
    backToDetails() {
      this.storeUpdateUI('detailsVisible', true);
    },
    showSection(d, x) {
      let r = false;

      if (d !== undefined && x !== undefined && d[x] !== undefined) {
        r = true;
      }

      return r;
    },
  },
};
</script>

<style lang="scss">
  @import '@/assets/scss/_variables.scss';

  #detailsSection {
    flex: 1;
    overflow: hidden;

    #detailsBreadcrumb {
      display: none;
      padding: 1rem 2rem;

      .bc-selected-cat,
      .bc-selected-abbrev {
        font-weight: 600;
      }
    }

    #detailsTitle {
      .details-title {
        display: inline-block;
        padding: 1rem 2rem;
        font-size: 2rem;
        font-weight: 600;
      }
    }

    #detailsContent {
      overflow: auto;

      .section-body {
        padding: 2.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.subsection-body {
          align-items: flex-start;
          padding-top: 0;
          padding-bottom: 1rem;
        }

        .section-body-sleeve {
          width: 55rem;
          max-width: 55rem;
          text-align: left;
        }

        p {
          margin: 0 0 1.6rem;

          &:last-child {
            margin: 0;
          }
        }

        .nlp {
          max-width: 60ch;
          line-height: 1.35;
          margin: 0 0 1rem;

          &:last-child {
            margin: 0;
          }

          strong {
            display: inline-block;
            padding-left: 4rem;
          }
        }

        v, v1, v2, .v, .v1, .v2 {
          display: block;
          margin: 0;
          line-height: 1.35;
          padding-top: 0.6rem;
        }
        v, .v {
          margin-left: 0;
        }
        v1, .v1 {
          margin-left: 2rem;
        }
        v2, .v2 {
          margin-left: 4rem;
        }
        .v span, .v1 span, .v2 span {
          display: block;
          padding-bottom: .3rem;
        }
        .br {
          display: block;
          margin-bottom: 2.4rem;
        }
        .nl {
          display: block;
          margin-bottom: 1rem;
        }

        p.textRow {
          margin: 1.6rem 0 0.8rem;
        }
        .chordGrid {
          p.chordRow {
            margin: 0 0 0 1.6rem;

            .chordSlot {
              display: inline-block;
              min-width: 8rem;
              padding: 0.4rem 0.8rem;
              margin: -0.1rem 0 0 -0.1rem;
              text-align: center;
            }

            .repeatSlot {
              padding: 0.4rem 0.4rem 0.4rem 1.6rem;
            }
          }
        }

        .chord_voicing {
          display: inline-block;
          min-width: 10rem;
          font-family: monospace;
        }
      }

      .details-subsections {
        padding-bottom: 1.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .details-subsection {
          width: 55rem;
          max-width: 55rem;
          text-align: left;
        }
      }
      .audio_tracks,
      .song_text_chords {
        .section-body {
          .section-body-sleeve {
            width: 100%;
            max-width: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    #detailsSection {
      #detailsBreadcrumb {
        display: block;

        .bc-selected-cat,
        .bc-selected-abbrev {
          cursor: pointer;
        }
      }

      #detailsTitle {
        display: none;
      }

      #detailsContent {
        .section-html,
        .section-html-array {
          text-align: center;

          .section-body {
            display: inline-block;
            width: 100%;
            text-align: left;
            line-height: 1.4;

            .section-body-sleeve {
              width: 100%;
            }

            .fog-list {
              padding: 0.6rem;
            }

            .nlp {
              margin: 0 0 0.8rem;
            }
          }
        }

        .details-subsections {
          .details-subsection {
            width: 100%;
          }
        }

        .audio_tracks {
          .section-body {
            padding: 2.4rem 0;

            .table-responsive {
              table td.track_link {
                padding: 0;

                a {
                  height: 4rem;
                  width: 4rem;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
