<template>
  <div class="details-subsections">
    <template
      v-for="(v, i) in html"
      :key="i"
    >
      <div
        v-if="v[index] !== null"
        class="details-subsection"
      >
        <subsection-header
          :label="v.title"
        />
        <div
          class="section-body subsection-body"
          v-html="v[index]"
        />
      </div>
    </template>
  </div>
</template>

<script>
import SubsectionHeader from './HeaderSubsection.vue';

export default {
  components: {
    SubsectionHeader,
  },
  props: {
    html: {
      type: Array,
      default: () => [],
    },
    index: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">

</style>
