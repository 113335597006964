<template>
  <div
    v-if="checkForDataPC(mainContent, subsectionContent, index) === true"
    class="pc-section"
  >
    <section-html-string
      :label="label"
      :html="mainContent[index]"
    />
    <sub-html-array
      v-if="checkForDataPC(null, subsectionContent, index) === true"
      :html="subsectionContent"
      :index="index"
    />
  </div>
</template>

<script>
import mixinFunctions from '@/mixins/functions';
import SectionHtmlString from '@/components/helpers/SectionHtmlString.vue';
import SubHtmlArray from '@/components/helpers/SubHtmlArray.vue';

export default {
  components: {
    SubHtmlArray,
    SectionHtmlString,
  },
  mixins: [
    mixinFunctions,
  ],

  props: {
    label: {
      type: String,
      default: '',
    },
    mainContent: {
      type: Object,
      default: () => {},
    },
    subsectionContent: {
      type: Array,
      default: () => [],
    },
    index: {
      type: String,
      default: '',
    },
  },
  computed: {
    mainText() {
      let r = '';
      if (this.mainContent[this.index] !== undefined) {
        if (this.mainContent[this.index] !== undefined) {
          // r = this.mainContent[this.index];
          r = 'acp';
        }
      }

      return r;
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">

</style>
