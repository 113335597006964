import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';

export default {
  mixins: [
  ],

  props: {
  },

  computed: {
    ...mapGetters([
      'ui',
    ]),

    routerCat() {
      return this.$route.params.fogCat;
    },
    routerAbbrev() {
      return this.$route.params.abbrev;
    },
    mapsData() {
      return this.$store.getters.mapsJSON;
    },
    detailsData() {
      return this.$store.getters.detailsJSON;
    },
    catTitle() {
      let r = '';
      if (this.mapsData.nav !== undefined) {
        if (this.mapsData.nav[this.routerCat] !== undefined) {
          r = this.mapsData.nav[this.routerCat].title;
        }
      }

      return r;
    },
    detailsTitle() {
      let r = '';

      if (this.detailsData.vue !== undefined) {
        if (this.detailsData.vue.title !== undefined) {
          r = this.detailsData.vue.title;
        }
      }

      return r;
    },
  },
  watch: {
  },
  methods: {
    ...mapActions('audioPlaylistPlayer', ['updatePlaylistIsVisible']),
    getHash() {
      const hash = {
        fogCat: '',
        abbrev: '',
      };
      if (this.$route.path !== '/') {
        const exploded = this.$route.path.split('/');
        if (exploded.length === 3) {
          /* eslint-disable-next-line prefer-destructuring */
          hash.abbrev = exploded[2];
        }
        if (exploded.length > 1) {
          /* eslint-disable-next-line prefer-destructuring */
          hash.fogCat = exploded[1];
        }
      }
      return hash;
    },

    getDetailsData(which) {
      let str = '';
      if (this.detailsData.vue !== undefined) {
        str = this.detailsData.vue[which];
      }
      return str;
    },

    // good above

    checkForDataPC(p, c, which = '') {
      // this.debugLog('checkForDataPC (' + which + '): ' + p + ' +++ ' + c);
      let r = false;
      if (p !== undefined && p !== null) {
        // this.debugLog('P exists');
        if (p[which] !== undefined && p[which] !== '' && p[which] !== null) {
          // this.debugLog('P has value: ' + p[which]);
          r = true;
        }
        // this.debugLog('checkForDataPC: ' + r);
      }
      if (c !== undefined && c !== null) {
        // this.debugLog('C has value');
        Object.entries(c).forEach((a, i) => {
          // this.debugLog('= ' + i);
          // this.debugLog('A' + c[i][which] + 'A');
          if (c[i][which] !== undefined && c[i][which] !== '' && c[i][which] !== null) {
            // this.debugLog('C' + i + ' has value');
            r = true;
          }
        });
      }
      // this.debugLog('checkForDataPC final: ' + r);
      /*
      */
      return r;
    },

    numInJSON(obj) {
      return Object.keys(obj).length;
    },

    uiForCatAbbrev() {
      let sourceListVisible = false;
      if (this.routerCat !== undefined && this.routerCat !== '' && this.routerCat !== 'home') {
        sourceListVisible = true;
      }

      let detailsVisible = false;
      if (this.routerAbbrev !== undefined && this.routerAbbrev !== '') {
        detailsVisible = true;
      }

      this.storeUpdateUI('detailsVisible', detailsVisible);
      this.storeUpdateUI('sourceListVisible', sourceListVisible);
    },

    showHideMenu(tf) {
      this.storeUpdateUI('menuVisible', tf);
    },

    closeOverlay() {
      this.storeUpdateUI('menuVisible', false);
      this.updatePlaylistIsVisible(false);
    },

    storeUpdateUI(f, v) {
      const pv = {
        f,
        v,
      };
      this.$store.dispatch('updateStateUI', pv);
    },

    storeUpdateCat(cat) {
      this.$store.dispatch('updateFogCat', cat);
    },

    storeUpdateAbbrev(abbrev) {
      this.$store.dispatch('updateFogAbbrev', abbrev);
    },

    storeUpdateMapsJSON(str) {
      this.$store.dispatch('updateMapsJSON', str);
    },

    storeUpdateDetailsJSON(str) {
      this.$store.dispatch('updateDetailsJSON', str);
    },

    async ajaxGetDetails(cat, abbrev = '') {
      if (abbrev !== '') {
        try {
          let q = `${this.$api}?type=${cat}&abbrev=${abbrev}`;
          if (this.$store.getters.isAdmin === true) {
            q = `${q}&p4C0`;
          }
          this.storeUpdateUI('loading', true);
          const res = await axios.post(q);
          const response = res.data.data;

          this.debugLog(`json for ${cat}/${abbrev}: `);

          if (cat !== 'maps') {
            this.storeUpdateDetailsJSON(response);
            this.storeUpdateUI('loading', false);
          } else {
            this.storeUpdateMapsJSON(response);
            this.storeUpdateUI('loading', false);
          }
          return response;
        } catch (e) {
          this.debugError(e);
        }
      }
      return null;
    },

    beforeAppMount() {
      try {
        let q = `${this.$api}?type=maps`;
        if (this.$store.getters.isAdmin === true) {
          q = `${q}&p4C0`;
        }
        axios.post(q).then((response) => {
          this.debugLog(response.data);
          this.json = JSON.stringify(response.data.origin);
          this.loadMaps(response.data.data);
          this.loadInitialHash();
        });
      } catch (e) {
        this.debugError(e);
      }
    },

    loadMaps(data) {
      this.storeUpdateMapsJSON(data);
      this.showNav = true;
    },
    // (1) update store with cat & abbrev from the hash (2) if an abbrev, get its data
    loadInitialHash() {
      let cat = '';
      if (this.routerCat !== undefined) {
        cat = this.routerCat;
      }
      let abbrev = '';
      if (this.routerAbbrev !== undefined) {
        abbrev = this.routerAbbrev;
      }

      // this.debugLog('loadInitialHash: ' + cat + '/' + abbrev);
      this.storeUpdateCat(cat);
      this.storeUpdateAbbrev(abbrev);
      this.uiForCatAbbrev();
      if (abbrev !== '') {
        this.ajaxGetDetails(cat, abbrev);
      }
    },
  },
};
