import { createWebHistory, createRouter } from 'vue-router';
import ListAndDetails from '../components/routes/ListAndDetails.vue';
import DetailsSection from '../components/routes/DetailsSection.vue';
// import HomePage from '../components/routes/HomePage.vue';

/*

artists
chamber
people
recordings
songs
*/

const routes = [
  {
    path: '',
    name: 'Home',
    props: true,
    components: {
      homeRoutedView: ListAndDetails,
    },
  },
  {
    path: '/:fogCat',
    name: 'ladRoutedView',
    props: true,
    components: {
      ladRoutedView: ListAndDetails,
    },
    children: [
      {
        path: '/:fogCat/:abbrev',
        name: 'detailsRoutedView',
        props: true,
        components: {
          detailsRoutedView: DetailsSection,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
