<template>
  <div id="latest" class="centered-vh">
    <div class="latest-releases">
      Latest Releases
    </div>
    <ul class="centered-vh">
      <li
        v-for="(arr, i) in d"
        :key="i"
      >
        <router-link
          :to="arr.path"
          class="router-link-active"
        >
          <!--
          <div
            class="title"
            v-html="arr.titleFoggyLink"
          />
          -->
          <load-image
            :filename="arr.image"
            title=""
          />
        </router-link>
        <div
          class="description"
          v-html="arr.descriptionFoggyLink"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import LoadImage from '@/components/helpers/LoadImage.vue';

export default {
  components: {
    LoadImage,
  },
  props: {
    d: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
#latest {
  flex-direction: column;

  .latest-releases {
    font-size: 2.4rem;
    font-size: 600;
  }

  img {
    max-height: 300px;
  }

  ul {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 400px;
      padding: 0 1rem;
    }
  }
}
</style>
