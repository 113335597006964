<template>
  <div class="details-section-header bg-section-header">
    <h4
      tabindex="-1"
    >
      {{ label }}
    </h4>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
  .details-section-header {
    padding: 1rem 2rem;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
  }
</style>
