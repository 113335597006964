<template>
  <div
    class="section-html-array"
  >
    <section-header
      :label="label"
    />
    <div class="section-body">
      <ul
        :aria-label="label"
        class="section-body-sleeve"
      >
        <li
          v-for="(v, key) in html"
          :key="key"
          class="nlp"
          v-html="v"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import SectionHeader from './HeaderSection.vue';

export default {
  components: {
    SectionHeader,
  },
  mixins: [
  ],
  props: {
    label: {
      type: String,
      default: '',
    },
    html: {
      type: Array,
      default: () => {},
    },
  },

  methods: {
  },
};
</script>

<style lang="scss">

</style>
