import {
  createStore,
  createLogger,
} from 'vuex';
import audioPlaylistPlayer from '../components/audioPlaylistPlayer/store/audioPlaylistPlayer';

// const debugMode = process.env.NODE_ENV !== 'production';
const debugMode = false;

const plugins = debugMode ? [createLogger({})] : [];

// Create a new store instance.
const store = createStore({
  modules: {
    audioPlaylistPlayer,
  },
  state() {
    return {
      debug: debugMode,
      isAdmin: false,
      ui: {
        loading: false,
        menuVisible: false,
        sourceListVisible: false,
        detailsVisible: false,
      },
      fogCat: 'home',
      fogAbbrev: '',
      mapsJSON: '',
      detailsJSON: '',
      emittedPlayer: {},
      emittedPlaylist: [],
    };
  },
  strict: debugMode,
  plugins,
  mutations: {
    mutateUI(state, pv) {
      state.ui[pv.f] = pv.v;
      // console.log('mutation: mutateUI: ' + pv.f + ' = ' + pv.v);
    },
    mutateFogCat(state, cat) {
      if (cat === '') {
        state.fogCat = 'home';
        state.fogAbbrev = '';
      } else {
        state.fogCat = cat;
      }
    },
    mutateFogAbbrev(state, abbrev) {
      state.fogAbbrev = abbrev;
    },
    mutateMapsJSON(state, json) {
      state.mapsJSON = json;
    },
    mutateDetailsJSON(state, json) {
      state.detailsJSON = json;
    },
    mutateEmittedPlayer(state, obj) {
      state.emittedPlayer = obj;
    },
    mutateEmittedPlaylist(state, obj) {
      state.emittedPlaylist = obj;
    },
    mutateIsAdmin(state, tf) {
      state.isAdmin = tf;
    },
  },
  getters: {
    entireState: (state) => state,
    debug: (state) => state.debug,
    isAdmin: (state) => state.isAdmin,
    ui: (state) => state.ui,
    fogCat: (state) => state.fogCat,
    fogAbbrev: (state) => state.fogAbbrev,
    mapsJSON: (state) => state.mapsJSON,
    detailsJSON: (state) => state.detailsJSON,
    emittedPlayer: (state) => state.emittedPlayer,
    emittedPlaylist: (state) => state.emittedPlaylist,
  },
  setters: {
  },
  methods: {
  },
  actions: {
    updateIsAdmin({ commit }, tf) {
      commit('mutateIsAdmin', tf);
    },
    updateStateUI({ commit }, pv) {
      commit('mutateUI', pv);
    },
    updateFogCat({ commit }, fogCat) {
      commit('mutateFogCat', fogCat);
      commit('mutateFogAbbrev', '');
      commit('mutateDetailsJSON', '');
    },
    updateFogAbbrev({ commit }, fogAbbrev) {
      commit('mutateFogAbbrev', fogAbbrev);
    },
    updateDetailsJSON({ commit }, json) {
      commit('mutateDetailsJSON', json);
    },
    updateMapsJSON({ commit }, json) {
      commit('mutateMapsJSON', json);
    },
    updateEmittedPlayer({ commit }, obj) {
      commit('mutateEmittedPlayer', obj);
    },
    updateEmittedPlaylist({ commit }, obj) {
      commit('mutateEmittedPlaylist', obj);
    },
  },
});

export default store;
