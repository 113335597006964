<template>
  <button
    class="foggy-icon play-one-audio"
    :aria-label="defaultAriaLabel"
    @click="loadJson(playlist)"
  >
    <span class="label">
      Play Track
    </span>
  </button>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    playlist: {
      type: Array,
      default: () => [],
    },
    ariaLabel: {
      type: String,
      default: '',
    },
  },
  computed: {

    defaultAriaLabel() {
      const t = this.playlist[0];
      let label = `Play "${t.title}", by ${t.artist}, from the recording ${t.album}. Duration ${t.duration}, ${t.version} version, year ${t.year}`;
      if (this.ariaLabel !== '') {
        label = this.ariaLabel;
      }
      return label;
    },
  },

  methods: {
    ...mapActions('audioPlaylistPlayer', ['updatePlaylistJSON']),
    loadJson(json) {
      this.updatePlaylistJSON(json);
    },
  },
};
</script>

<style lang="scss">
.play-one-audio {
  span.label {
    display: none;
  }
}
</style>
