import { createApp } from 'vue';
// import { Vuex } from 'vuex';
import App from './App.vue';
import store from './store';
import router from './router';

const app = createApp(App);

// alert(window.location.hostname);
// let hostip = '209.23.9.35';
let hostip = 'www.foggyrealm.com';
let apiAddr = `https://${hostip}/api/getJSON.php`;
let audioDir = `https://${hostip}/fog_audio`;
let imagesDir = `https://${hostip}/fog_images`;

if (window.location.hostname === '209.23.9.35') {
  hostip = '209.23.9.35:8080';
}

if (window.location.hostname === 'localhost') {
  hostip = 'localhost:8080';
  apiAddr = `http://${hostip}/php/fog_php/api/getJSON.php`;
  audioDir = `http://${hostip}/fog_audio`;
  imagesDir = `http://${hostip}/fog_images`;
}

// console.log(apiAddr);

app.config.globalProperties.$api = apiAddr;
app.config.globalProperties.$audio = audioDir;
app.config.globalProperties.$images = imagesDir;

// app.use(Vuex);
app.use(store);
app.use(router);

app.mixin({
  methods: {
    debugLog(str) {
      if (this.$store.getters.debug === true) {
        console.log(str);
      }
    },
    debugError(str) {
      if (this.$store.getters.debug === true) {
        console.error(str);
      }
    },
    jstring(json) {
      return JSON.stringify(json);
    },
  },
});

app.mount('#app');
