<template>
  <h5
    tabindex="-1"
    class="details-subsection-header"
  >
    {{ label }}
  </h5>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
  .details-subsection-header {
    background-color: transparent;
    padding: 2.4rem 2.4rem 1rem;
    font-size: 1.6rem;
    font-weight: bold;
  }
</style>
