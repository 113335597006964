<template>
  <div
    class="title-image"
  >
    <img
      :src="imageSource(filename)"
      :alt="altText"
    >
  </div>
</template>

<script>
import mixinFunctions from '@/mixins/functions';

export default {
  mixins: [
    mixinFunctions,
  ],
  props: {
    filename: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    altText() {
      let r = `photo of ${this.title}`;
      if (this.routerCat === 'recordings') {
        r = `CD cover of ${this.title}`;
      }
      return r;
    },
  },
  methods: {
    imageSource(f) {
      let src = false;
      try {
        /* eslint-disable-next-line global-require */
        src = `${this.$images}/${f}`;
      } catch {
        this.debugLog(`not found: @assets/images/${f}`);
      }
      return src;
    },
  },
};
</script>

<style lang="scss">
  .title-image {
    padding: 1.6rem;
    text-align: center;

    img {
      max-width: 50rem;
      max-height: 60rem;
    }
  }

  @media screen and (max-width: 1000px) {
    .title-image {
      img {
        max-width: 100%;
      }
    }
  }
</style>
