<template>
  <div
    v-show="storeUI_menuVisible === true || storeUI_playlistVisible === true || storeUI_loading === true"
    id="appTransientOverlay"
    class="bg-overlay"
    @click="closeOverlay"
    @keydown="closeOverlay"
  >
  </div>
</template>

<script>
import mixinFunctions from '@/mixins/functions';
import { mapGetters } from 'vuex';

export default {
  name: 'OverlayPanel',
  mixins: [
    mixinFunctions,
  ],
  computed: {
    ...mapGetters([
      'ui',
    ]),
    storeUI_loading() {
      return this.ui.loading;
    },
    storeUI_menuVisible() {
      return this.ui.menuVisible;
    },
    storeUI_playlistVisible() {
      return this.ui.playlistVisible;
    },
  },
  updated() {
    // this.debugLog('Overlay updated');
  },
  watch: {
    $route() {
      // via router... if loading ListAndDetails, pass cat to globals; if loading Details...
      // this.debugLog('routeWatcher in Overlay');
    },
  },
};
</script>
