<template>
  <button
    class="icon-and-label-button"
    :aria-label="showHidePlayerLabel"
    @click="showHidePlayer"
  >
    <span class="foggy-icon show-hide-player"/>
    <span class="label">
      {{ showHidePlayerLabel }}
    </span>
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
  },
  computed: {
    ...mapGetters('audioPlaylistPlayer', ['playerIsVisible']),
    showHidePlayerLabel() {
      let r = 'Show Audio Player';
      if (this.playerIsVisible === true) {
        r = 'Hide Audio Player';
      }
      return r;
    },
  },
  methods: {
    ...mapActions('audioPlaylistPlayer', ['updatePlayerIsVisible']),
    showHidePlayer() {
      this.updatePlayerIsVisible(!this.playerIsVisible);
    },
  },
};
</script>

<style lang="scss">

</style>
