<template>
  <div
    id="app2"
    class="bg1 flexV flex1"
  >
    <overlay-panel></overlay-panel>
    <!--
    <div class="origin">
      #{{ json }}#
    </div>
    -->
    <template v-if="showNav === true">
      <nav-section />
      <div id="appPlayerSection">
        <fog-player @emittedPlayer="receiveEmittedPlayer" @emittedPlaylist="receiveEmittedPlaylist" />
      </div>
      <div
        id="appContentSection"
        class="flexV flex1"
      >
        <template v-if="fogCat === 'home'">
          <home-page />
        </template>
        <template v-else>
          <router-view name="ladRoutedView" />
        </template>
      </div>
    </template>
    <template v-else>
      <div
        id="appLoading"
        class="flex1 centered-vh"
      >
        <loading-page />
      </div>
    </template>
  </div>
</template>

<script>
import mixinFunctions from '@/mixins/functions';
import { mapGetters, mapActions } from 'vuex';
import LoadingPage from '@/components/initialPages/LoadingPage.vue';
import FogPlayer from '@/components/audioPlaylistPlayer/AudioPlaylistPlayer.vue';
import HomePage from '@/components/initialPages/HomePage.vue';
import NavSection from '@/components/mainSections/NavSection.vue';
import OverlayPanel from '@/components/helpers/OverlayPanel.vue';

export default {
  name: 'App',
  components: {
    OverlayPanel,
    LoadingPage,
    FogPlayer,
    HomePage,
    NavSection,
  },
  mixins: [
    mixinFunctions,
  ],
  data() {
    return {
      showNav: false,
      json: {},
    };
  },
  computed: {
    ...mapGetters([
      'fogCat',
    ]),
  },
  watch: {
    routerCat() {
      if (this.routerCat !== undefined) {
        this.storeUpdateCat(this.routerCat);
        this.uiForCatAbbrev();
      }
    },
    routerAbbrev() {
      this.storeUpdateAbbrev(this.routerAbbrev);
      this.uiForCatAbbrev();
      this.ajaxGetDetails(this.routerCat, this.routerAbbrev);
    },
  },
  beforeMount() {
    if (window.location.search === '?p4C0') {
      this.updateIsAdmin(true);
    }
    this.beforeAppMount();
  },
  /*
  mounted() {
    window.addEventListener('click', (e) => {
      e.preventDefault();
      if (e.target.closest('a') !== undefined && e.target.closest('a') !== null) {
        this.handleLinkEvent(e.target.closest('a'));
      }
    });
  },
  */
  methods: {
    ...mapActions(['updateEmittedPlayer', 'updateEmittedPlaylist', 'updateIsAdmin']),
    receiveEmittedPlayer(obj) {
      this.updateEmittedPlayer(obj);
    },
    receiveEmittedPlaylist(obj) {
      this.updateEmittedPlaylist(obj);
    },
    /*
    handleLinkEvent(targ) {
      // this.debugLog('handleLinkEvent: ' + href);
      // alert(JSON.stringify(targ));
      let currentHash = window.location.pathname;
      // const beforeHash = window.location.href.replace(currentHash, '');
      const beforeHash = window.location.origin;
      // console.log(`this.$router: ${JSON.stringify(this.$router.currentRoute._rawValue)}`);
      // beforeHash = beforeHash.replace(window.location.origin, '');
      // pathname

      // get the "new hash"; use currentHash if it is '/' (to avoid passing the domain to the hash)
      let newHash = targ.href.replace(beforeHash, '');
      if (currentHash === '/') {
        // newHash = currentHash;
      }
      if (newHash === '/home') {
        newHash = '/';
      } else if (currentHash === '/') {
        currentHash = '/home';
      }
      // console.log(`targ.href: ${targ.href}\nwindow.location.origin (beforeHash): ${beforeHash}\ncurrentHash: ${currentHash}\nnewHash: ${newHash}`);

      this.storeUpdateUI('loading', true);
      // this.debugLog('handleLinkEvent (App.vue)');
      // console.log(newHash);
      this.$router.push(newHash);
      this.$nextTick(() => {
        this.storeUpdateUI('loading', false);
      });
    },
    */
  },
};
</script>

<style lang="scss">
  @import '@/assets/scss/_variables.scss';

  div {
    position: relative;
    box-sizing: border-box;
  }

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  ul {
    display: inline-block;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    list-style-type: none;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 1.6rem;
    font-weight: normal;
  }

  .flexH {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;

    .flex1.scrollarea {
      overflow: auto;
    }
  }
  .flexV {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    .flex1.scrollarea {
      overflow: auto;
    }
  }

  .flex1 {
    flex: 1;
    min-height: 0;
    min-width: 0;
  }
  .hidden {
    display: none;
  }
  .centered-vh {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  html {
    font-family: sans-serif;
    font-size: 10px;

    body {
      margin: 0;
      font-size: 1.6rem;
      line-height: 1.35;

      #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow-y: hidden;

        #appTransientOverlay {
          position: absolute;
          z-index: 5;
          height: 100%;
          width: 100%;
        }

        input {
          padding: 0.4rem 0.8rem;
          -webkit-appearance: none;
          border-radius: 1rem;
          font-size: 1.4rem;
        }

        button {
          &.icon-button {
            -webkit-appearance: none;
            background: none;
            border: none;
          }

          &.icon-label-button {
            -webkit-appearance: none;
            background: none;
            border: none;
          }

          &[disabled="disabled"],
          &[disabled] {
            opacity: 0.3;
          }
        }

        table {
          border-collapse: collapse;
        }
      }
    }
  }

  .icon-and-label-button {
    display: inline-flex;
    padding: 0.2rem 0.8rem;
    background-color: transparent;
    border: none;
    border-radius: 0.4rem;
    font-size: 1.6rem;
    -webkit-appearance: none;
    justify-content: center;
    align-items: center;

    .label {
      display: inline-block;
      padding: 0 0 0 0.5rem;
    }
  }

  a.icon-and-label-button {
    text-decoration: none;
    height: 3rem;
    box-sizing: border-box;
    line-height: 1;
  }

  .foggy-icon {
    display: inline-block;
    padding: 0rem;
    border-radius: 0.4rem;
    vertical-align: middle;
    height: 2.4rem;
    width: 2.4rem;
    mask: url(assets/icons/PlayOne.svg) no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;

    &.play-one-audio {
      mask-image: url(assets/icons/PlayOne.svg);
    }
    &.play-all-audio {
      mask-image: url(assets/icons/PlayAll.svg);
    }
    &.show-hide-player {
      mask-image: url(assets/icons/Player.svg);
    }
    &.show-hide-playlist {
      mask-image: url(assets/icons/Playlist.svg);
    }
    &.show-hide-nav {
      mask-image: url(assets/icons/Hamburger.svg);
    }
    &.download-zip {
      mask-image: url(assets/icons/Download.svg);
      mask-size: auto;
      height: 1.8rem;
    }
  }

  @media screen and (max-width: 1000px) {
    .icon-and-label-button {
      .label {
        display: none;
      }
    }
  }

  .origin {
    display: none;
  }
</style>
