export default {
  namespaced: true,
  strict: true,
  state() {
    return {
    /*
    playlistJSON: [
      {
        title: '',
        artist: '',
        album: '',
        duration: '0:00',
        filename: '',
        file: '',
        year: '',
        version: '',
        image: '',
      },
    ],
    */
      playlistJSON: [],
      playerIsVisible: false,
      playlistIsVisible: false,
      player: {
        playerIsPlaying: false,
        playerIsMuted: false,
        totalTrackNum: 0,
        currentTrackNum: 0,
        currentTrack: {},
      },
    };
  },
  getters: {
    playlistJSON: (state) => state.playlistJSON,
    playerIsVisible: (state) => state.playerIsVisible,
    playlistIsVisible: (state) => state.playlistIsVisible,
    player: (state) => state.player,
  },
  actions: {
    updatePlaylistJSON({ commit }, json) {
      commit('mutatePlaylistJSON', json);
    },
    updatePlayerIsVisible({ commit }, tf) {
      commit('mutatePlayerIsVisible', tf);
    },
    updatePlaylistIsVisible({ commit }, tf) {
      commit('mutatePlaylistIsVisible', tf);
    },
    updatePlayer({ commit }, obj) {
      commit('mutatePlayer', obj);
    },
  },
  mutations: {
    mutatePlaylistJSON(state, json) {
      state.playlistJSON = json;
    },
    mutatePlayerIsVisible(state, tf) {
      state.playerIsVisible = tf;
    },
    mutatePlaylistIsVisible(state, tf) {
      state.playlistIsVisible = tf;
    },
    mutatePlayerField(state, obj) {
      state.player[obj.field] = obj.value;
    },
    mutatePlayer(state, obj) {
      state.player.playerIsPlaying = obj.playerIsPlaying;
      state.player.playerIsMuted = obj.playerIsMuted;
      state.player.playlistIsVisible = obj.playlistIsVisible;
      state.player.currentTrackNum = obj.currentTrackNum;
      state.player.currentTrack = obj.currentTrack;
      state.player.totalTrackNum = obj.totalTrackNum;
    },
  },
};
