<template>
  <div
    id="ladContent"
    class="flexH flex1"
    :class="{'cat': storeUI_sourceListVisible, 'abbrev': storeUI_detailsVisible}"
  >
    <div
      id="ladSourceList"
      class="frame-r"
    >
      <source-list
        :fog-cat="routerCat"
      />
    </div>
    <div
      v-show="abbrevIsSelected"
      id="ladDetailsSection"
      class="flex1"
    >
      <router-view name="detailsRoutedView" />
    </div>
    <div
      v-show="storeUI_sourceListVisible && !abbrevIsSelected"
      id="ladDetailsSplash"
      class="centered-vh flex1"
    >
      <div class="instruction-text">
        Select an item to view the details
      </div>
    </div>
  </div>
</template>

<script>
import mixinFunctions from '@/mixins/functions';
import SourceList from '@/components/mainSections/SourceListSection.vue';

export default {
  components: {
    SourceList,
  },
  mixins: [
    mixinFunctions,
  ],
  props: {
    fogCat: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    abbrevIsSelected() {
      let r = false;

      if (this.routerAbbrev !== '') {
        r = true;
      }

      return r;
    },
    storeUI_sourceListVisible() {
      return this.$store.getters.ui.sourceListVisible;
    },
    storeUI_detailsVisible() {
      return this.$store.getters.ui.detailsVisible;
    },
  },
  updated() {
  },
  mounted() {
    this.uiForCatAbbrev();
  },
  methods: {
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

  #ladContent {
    #ladSourceList {
      display: flex;
      flex-direction: column;
      min-width: 20%;
      max-width: 30%;
    }
    #ladDetailsSection {
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 1000px) {
    #ladContent {
      flex-direction: column;
      overflow-y: hidden;

      #ladSourceList {
        flex: 1;
        max-width: none;
        width: 100%;
        text-align: left;
        overflow: hidden;

        #sourceList {
          ul li {
            text-align: left;

            a {
              padding: 0.5rem 2rem;

              &:focus {
                padding: 0.2rem 1.7rem;
              }
            }
          }
        }
      }

      #ladDetailsSection {
        display: none;
        flex: 1;
        overflow-y: auto;
      }

      #ladDetailsSplash {
        display: none;
      }

      &.abbrev {
        #ladSourceList {
          display: none;
        }

        #ladDetailsSection {
          display: flex;
        }
      }
    }
  }
  /* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
