<template>
  <div class="table-html row">
    <div class="filter-totals">
      <div>
        <load-playlist-button
          :playlist="playlist"
          label="Play All Tracks"
        />
      </div>
      <div class="remainder">Total Items: {{ rowsWithDiscNums.length }}</div>
      <a
        :href="downloadFile"
        class="icon-and-label-button"
      >
        <span class="foggy-icon download-zip"/>
        <span class="label">
          Download Audio
        </span>
      </a>

    </div>

    <div class="table-list">
      <ul>
        <template
          v-for="(r, index) in rowsWithDiscNums"
          :key="index"
        >
          <li
            v-if="r.isNewDisc === true"
            class="frame-trbl"
          >
            <td
              class="disc-num-row"
              colspan="7"
            >
              Disc {{ r.disc_num }}
            </td>
          </li>
          <li class="frame-trbl">
            <div class="audio">
              <load-track-button :playlist="makePlaylistOneSong(r)" />
            </div>
            <div class="track-info" aria-hidden="true">
              <div class="line-1">
                <div class="song_title flex1">
                  <span v-if="this.daFogCat == 'songs' || r.song_link === false">
                    {{ r.song_title }}
                  </span>
                  <span v-else>
                    <router-link
                      :to="`/songs/${r.song_abbrev}`"
                      class="router-link-active"
                    >
                      {{ r.song_title }}
                    </router-link>
                  </span>
                </div>
                <div class="track_duration">
                  {{ r.track_duration }}
                </div>
              </div>
              <div class="line-2">
                <div class="artist_title flex1">
                  <span v-if="this.daFogCat == 'artists'">
                    {{ r.artist_title }}
                  </span>
                  <span v-else>
                    <router-link
                      :to="`/artists/${r.artist_abbrev}`"
                      class="router-link-active"
                    >
                      {{ r.artist_title }}
                    </router-link>
                  </span>
                </div>
                <div class="track_type">
                  {{ r.track_type }}
                </div>
              </div>
              <div class="line-3">
                <div class="recording_title flex1">
                  <span v-if="this.daFogCat == 'recordings'">
                    {{ r.album_title }}
                  </span>
                  <span v-else>
                    <router-link
                      :to="`/recordings/${r.album_abbrev}`"
                      class="router-link-active"
                    >
                      {{ r.album_title }}
                    </router-link>
                  </span>
                </div>
                <div class="track_year">
                  {{ r.track_year }}
                </div>
              </div>
            </div>
          </li>
        </template>
      </ul>
    </div>

    <div
      id="daTable"
      class="table-responsive"
    >
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Song</th>
            <th>Artist</th>
            <th>Recording</th>
            <th>Duration</th>
            <th>Type</th>
            <th>Year</th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(r, index) in rowsWithDiscNums"
            :key="index"
          >
            <tr
              v-if="r.isNewDisc === true"
            >
              <td
                class="disc-num-row"
                colspan="7"
              >
                Disc {{ r.disc_num }}
              </td>
            </tr>
            <tr>
              <td class="audio">
                <load-track-button :playlist="makePlaylistOneSong(r)" />
              </td>
              <td class="song_title">
                <span v-if="this.daFogCat == 'songs'">
                  {{ r.song_title }}
                </span>
                <span v-else>
                  <router-link
                    :to="`/songs/${r.song_abbrev}`"
                    class="router-link-active"
                  >
                    {{ r.song_title }}
                  </router-link>
                </span>
              </td>
              <td class="artist_title">
                <span v-if="this.daFogCat == 'artists'">
                  {{ r.artist_title }}
                </span>
                <span v-else>
                  <router-link
                    :to="`/artists/${r.artist_abbrev}`"
                    class="router-link-active"
                  >
                    {{ r.artist_title }}
                  </router-link>
                </span>
              </td>
              <td class="recording_title">
                <span v-if="this.daFogCat == 'recordings'">
                  {{ r.album_title }}
                </span>
                <span v-else>
                  <router-link
                    :to="`/recordings/${r.album_abbrev}`"
                    class="router-link-active"
                  >
                    {{ r.album_title }}
                  </router-link>
                </span>
              </td>
              <td class="track_duration">
                {{ r.track_duration }}
              </td>
              <td class="track_type">
                {{ r.track_type }}
              </td>
              <td class="track_year">
                {{ r.track_year }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import mixinFunctions from '@/mixins/functions';
import loadTrackButton from '@/components/audioPlaylistPlayer/buttons/loadTrackButton.vue';
import loadPlaylistButton from '@/components/audioPlaylistPlayer/buttons/loadPlaylistButton.vue';

export default {
  components: {
    loadTrackButton,
    loadPlaylistButton,
  },
  mixins: [
    mixinFunctions,
  ],
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    dl: {
      type: String,
      default: '',
    },
    playlist: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filter: '',
      page: 1,
      currentDiscNum: 0,
    };
  },
  computed: {
    daFogCat() {
      return this.$route.params.fogCat;
    },
    downloadFile() {
      return `${this.$audio}/${this.dl}`;
    },
    numDiscs() {
      const r = parseInt(this.detailsData.vue.coll_num_discs, 10);
      return r;
    },
    rowsWithDiscNums() {
      const darr = [];
      let t = {};
      Object.values(this.rows).forEach((arr) => {
        t = arr;
        t.isNewDisc = this.isNewDisc(arr.disc_num);
        darr.push(t);
      });
      return darr;
    },
  },
  updated() {
    /*
    this.$refs.fogDatatable.page = 1;
    this.$refs.fogDatatable.processRows();
    */
  },

  methods: {
    isNewDisc(p) {
      let r = false;
      if (this.numDiscs > 1) {
        const discInt = parseInt(p, 10);
        // console.log(`discInt: ${discInt}`);
        // The console.log(`currentDiscNum: ${this.currentDiscNum}`);
        if (this.currentDiscNum < discInt) {
          r = true;
          this.currentDiscNum = discInt;
        }
      }
      return r;
    },
    makePlaylistOneSong(p) {
      const r = [];
      const obj = {
        file: p.track_link,
        title: p.song_title,
        artist: p.artist_title,
        album: p.album_title,
        year: p.track_year,
        version: p.track_type,
        duration: p.track_duration,
        image: p.cd_image,
      };
      r.push(obj);
      return r;
    },
  },
};
</script>

<style lang="scss">
  .filter-totals {
    display: flex;
    align-items: center;
    padding: 0 2.4rem 2.4rem;

    .form-group {
      flex: 1;
      padding-right: 1rem;
      text-align: right;
    }

    .remainder {
      flex: 1;
      display: inline-flex;
      height: 3rem;
      padding: 0 0 0 2rem;
      align-items: center;
    }
  }

  .table-list {
    display: none;
  }

  .disc-num-row {
    text-align: center;
    font-weight: 600;
  }

  .table-responsive {
    table {
      border-collapse: collapse;
      width: 100%;

      th,
      td {
        padding: 0.8rem;

        &.track_link {
          padding: 0 0.8rem;
        }
      }
    }

    ul {
      padding: 1rem;

      li {
        display: inline-block;
        padding: .5rem 1rem;
        margin-right: 1rem;
        border-radius: .5rem;

        &.disabled {
          opacity: 0.3;
        }
      }
    }
  }
  .mobile-column {
    display: none;

    .mobile-column-1 {
      flex: 1;
    }

    .mobile-column-2 {
      text-align: right;
    }
  }

  .track_duration,
  .track_year,
  .mobile-table-duration,
  .mobile_table-year {
    text-align: right;
  }

  .cat_artists .artist_title {
    display: none;
  }

  @media screen and (max-width: 1000px) {
    .table-list {
      display: flex;
      flex-direction: row;

      ul {
        flex: 1;
        padding: 0 2rem;

        li {
          display: flex;
          flex-direction: row;
          padding: 1rem;
          border-top-width: 0px;

          &:first-child {
            border-top-width: 1px;
          }

          .audio {
            padding: 0.5rem 1rem 0 0;

            .foggy-icon.play-one-audio {
              height: 3.6rem;
              width: 3.6rem;
            }
          }

          .track-info {
            flex: 1;

            .line-1,
            .line-2,
            .line-3 {
              display: flex;
              padding: 0.5rem 0;

              .flex1 {
                padding-right: 1rem;
              }
            }
          }
        }
      }
    }

    .table-responsive table {
      th,
      .audio,
      .artist_title,
      .song_title,
      .recording_title,
      .track_type,
      .track_year,
      .track_duration,
      .disc-num-row {
        display: none;
      }
      .mobile-column {
        display: block;
        padding: 1.2rem;

        .mobile-table-row {
          display: flex;

          .mobile-column-1 {
            flex: 1;

            &.mobile_table-recording {
              padding-top: 1rem;
            }
          }
          .mobile-column-2 {
          }
        }
      }
      .cat_recordings {
        .mobile_table-recording {
          display: none;
        }
      }

      .mobile-album_title {
        font-style: italic;
      }

      .table-responsive {
      }

      .track_link {
        .foggy-icon {
          height: 4rem;
          width: 4rem;
        }
      }
    }
  }
</style>
