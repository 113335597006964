<template>
  <nav
    id="sourceList"
    aria-labelledby="source-list-title"
    class="selections flexV"
  >
    <div class="frame-b">
      <h2
        id="source-list-title"
        tabindex="-1"
      >
        {{ catTitle }}
      </h2>
    </div>
    <div class="flex1 scrollarea">
      <ul
        :data-source="fogCat"
        :aria-labelledby="catTitle"
      >
        <li
          v-for="item in currentCatSLData"
          :key="item.abbrev"
        >
          <router-link
            :to="{ name: 'detailsRoutedView', params: { abbrev: whichKey(item) }}"
            v-html="titleOrFull(item)"
          />
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import mixinFunctions from '@/mixins/functions';

export default {
  mixins: [
    mixinFunctions,
  ],
  props: {
    fogCat: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
  computed: {
    mapsData() {
      return this.$store.getters.mapsJSON;
    },
    currentCatSLData() {
      return this.mapsData[this.fogCat];
    },
  },

  methods: {
    /* which title to show */
    titleOrFull(item) {
      let title = item.alpha_title;

      if (this.fogCat === 'recordings') {
        title = item.artist_album_title;
      }

      if (item.visible === false) {
        title = `<span class="private">${title} [private]</span>`;
      }
      return title;
    },
    /* which key to send to the details route */
    whichKey(item) {
      let title = item.abbrev;

      if (this.fogCat === 'recordings') {
        title = item.album_abbrev;
      }

      return title;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

  #sourceList {
    width: 100%;

    #source-list-title {
      padding: 1rem;
      font-size: 2.0rem;
      font-weight: 600;
    }

    ul {
      display: block;

      li {
        display: block;

        a {
          display: block;
          padding: 0.5rem 1rem;
          text-decoration: none;

          &:focus {
            padding: 0.2rem 0.7rem;
            margin: 3px;
            outline-offset: 0;
            outline-width: 3px;
          }
        }
      }
    }
  }
</style>
