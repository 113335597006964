<template>
  <div id="initLoading">
    <span class="msg instruction-text">
      foggyrealm.com is loading…
    </span>
  </div>
</template>

<script>
export default {
  name: 'LoadingPage',
};
</script>

<style lang="scss">
  .msg {
    text-align: center;
  }
</style>
