<template>
  <div id="audioPlayerWrapper">
    <div
      id="listOverlay"
      v-show="c_playlistIsVisible === true"
      class="bg-overlay"
      @click="closeOverlay()"
      @keydown="closeOverlay()"
    />
    <audio
      id="audioPlayer"
      @canplay="eventCanPlay"
      @timeupdate="eventTimeUpdate"
      @ended="eventPlayEnded"
      @error="eventLoadError"
    >
      <track kind="captions" />
      <source
        src=""
        type="audio/mpeg"
      >
      Your browser does not support the audio element.
    </audio>
    <div
      v-show="storePlayerIsVisible === true"
      id="fpConsole"
      class="frame-b"
    >
      <div id="currentlyPlaying">
        <div class="art">
          <img
            :src="appData.currentTrack.image"
            alt=""
          >
        </div>
        <div class="trackInfo">
          <div
            id="currentTrack"
            v-html="c_currentTrack"
          />
          <div
            id="progressBarDiv"
            class="flexH"
          >
            <div id="playerTrackElapsed" />
            <button
              id="progressBar"
              class="frame-trbl"
              @click="playerSkip"
            >
              <div
                id="progressBarBar"
                class="inverted"
              />
            </button>
            <div id="playerTrackRemain" />
          </div>
          <div
            id="currentArtist"
            v-html="c_currentArtist"
          />
        </div>
        <div class="mobilePB">
          <div class="art">
            <img
              :src="appData.currentTrack.image"
              alt=""
            >
          </div>
          <div class="playbackButtons">
            <button
              id="playerBtnPrev"
              aria-label="Previous Track"
              :disabled="checkDisabled('playerBtnPrev')"
              @click="playerCtrl('prev')"
            >
              <span class="button-icon-mask foggy-icon audio-button-prev" />
            </button>
            <button
              id="playerBtnPlayPause"
              :disabled="checkDisabled('playerBtnPlayPause')"
              @click="playerCtrl('play')"
            >
              <span
                class="button-icon-mask foggy-icon"
                :class="c_playerIsPlaying ? 'audio-button-pause' : 'audio-button-play'"
              />
            </button>
            <button
              id="playerBtnNext"
              aria-label="Next Track"
              :disabled="checkDisabled('playerBtnNext')"
              @click="playerCtrl('next')"
            >
              <span class="button-icon-mask foggy-icon audio-button-next"/>
            </button>
            <button
              id="playerBtnMuteUnmute"
              @click="playerCtrl('mute')"
              :disabled="checkDisabled('playerBtnMuteUnmute')"
            >
              <span
                class="button-icon-mask foggy-icon"
                :class="c_playerIsMuted ? 'audio-button-muted' : 'audio-button-not-muted'"
              />
            </button>
          </div>
          <div class="showPlaylistButton">
            <button
              id="playerShowPlaylistButton"
              class="icon-and-label-button a1"
              :aria-label="showHidePlaylistLabel"
              :disabled="checkDisabled('playerShowPlaylistButton')"
              @click="showHidePlaylist"
            >
              <span class="foggy-icon show-hide-playlist"/>
              <span class="label">
                {{ showHidePlaylistLabel }}
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="showPlaylistButton">
        <button
          id="playerShowPlaylistButton"
          class="icon-and-label-button a2"
          :aria-label="showHidePlaylistLabel"
          :disabled="checkDisabled('playerShowPlaylistButton')"
          @click="showHidePlaylist"
        >
          <span class="foggy-icon show-hide-playlist"/>
          <span class="label">
            {{ showHidePlaylistLabel }}
          </span>
        </button>
      </div>
    </div>
    <div class="fogPlaylistSleeve">
      <div
        v-show="c_playlistIsVisible === true"
        id="fogPlaylist"
        class="overlay-panel bg1"
      >
        <table>
          <tr
            v-for="(item, index) in appPlaylist"
            :key="index"
            :data-track="index"
            :selected="appData.currentTrackNum === index"
            @click="changeTrack(index)"
          >
            <td class="mobile">
              <div class="playlist-row-1 flexH">
                <div class="title flex1">
                  {{ item.title }}
                </div>
                <div class="duration">
                  {{ item.duration }}
                </div>
              </div>
              <div class="playlist-row-2">
                {{ item.artist }}—{{ item.album }}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import mixinFunctions from '@/mixins/functions';
import {
  mapGetters,
  mapActions,
} from 'vuex';

export default {
  mixins: [
    mixinFunctions,
  ],
  props: {
    jsonPlaylist: {
      type: String,
      default: '',
    },
  },
  emits: ['emittedPlayer', 'emittedPlaylist'],
  data() {
    return {
      appPlaylist: [],
      appData: {
        playerIsPlaying: false,
        playerIsMuted: false,
        playlistIsVisible: false,
        totalTrackNum: 0,
        currentTrackNum: 0,
        currentTrack: {},
      },
    };
  },
  computed: {
    ...mapGetters('audioPlaylistPlayer', ['playlistJSON', 'playerIsVisible']),
    // this is a json string passed as a prop to this component
    propPlaylist() {
      return this.jsonPlaylist;
    },
    // this is the playlist from the store
    storePlaylist() {
      return this.playlistJSON;
    },
    // this is the playlist from the local data
    localPlaylist() {
      return this.appPlaylist;
    },
    storePlayerIsVisible() {
      return this.playerIsVisible;
    },
    c_emptyPlaylist() {
      return this.appPlaylist.length === 0;
    },
    c_currentTrack() {
      let str = '';
      if (this.appData.currentTrack.title !== undefined) {
        str = this.appData.currentTrack.title;
      }
      return str;
    },
    c_currentTrackFile() {
      return this.appData.currentTrack.file;
    },
    c_currentArtist() {
      let str = '';
      if (this.appData.currentTrack.artist !== undefined) {
        str = this.appData.currentTrack.artist;
      }
      if (this.appData.currentTrack.album !== undefined) {
        str = `${str}—${this.appData.currentTrack.album}`;
      }
      return str;
    },
    c_playerIsPlaying() {
      return this.appData.playerIsPlaying;
    },
    c_playerIsMuted() {
      return this.appData.playerIsMuted;
    },
    c_playlistIsVisible() {
      return this.appData.playlistIsVisible;
    },
    // returns the number of tracks in the current playlist
    c_totalTrackNum() {
      return this.appPlaylist.length;
    },
    c_currentTrackNum() {
      return this.appPlaylist.currentTrackNum;
    },

    showHidePlaylistLabel() {
      let r = 'Show Playlist';
      if (this.c_playlistIsVisible === true) {
        r = 'Hide Playlist';
      }
      return r;
    },
    // returns tf if the last track of the playlist is playing
    isPlayingLastTrack() {
      let r = false;
      if (this.c_totalTrackNum === 0) {
        r = false;
      } else if (this.appData.currentTrackNum >= (this.c_totalTrackNum - 1)) {
        r = true;
      }
      return r;
    },
    // alias for the <audio> element
    audioPlayer() {
      return document.getElementById('audioPlayer');
    },
    // aliases for the audio controls
    fp() {
      const f = {
        wrapper: document.getElementById('audioPlayerWrapper'),
        art: document.getElementById('currentlyPlayingArt'),
        btnPlay: document.getElementById('playerBtnPlay'),
        btnPause: document.getElementById('playerBtnPause'),
        btnMute: document.getElementById('playerBtnMute'),
        btnUnmute: document.getElementById('playerBtnUnmute'),
        bar: document.getElementById('progressBarBar'),
        barShell: document.getElementById('progressBar'),
        elapsed: document.getElementById('playerTrackElapsed'),
        remaining: document.getElementById('playerTrackRemain'),
      };
      return f;
    },
  },
  watch: {
    c_playerIsPlaying() {
      this.broadcastPlayerData();
    },
    c_playerIsMuted() {
      this.broadcastPlayerData();
    },
    c_playlistIsVisible() {
      this.broadcastPlayerData();
    },
    c_currentTrackNum() {
      this.broadcastPlayerData();
    },
    c_totalTrackNum() {
      // alert('watch c_totalTrackNum');
      this.broadcastPlayerData();
    },
    c_currentTrackFile() {
      this.broadcastPlayerData();
    },
    storePlaylist(json) {
      this.loadNewPlaylist(json);
      // this.$emit('emittedPlaylist', this.appPlaylist);
    },
  },
  methods: {
    ...mapActions('audioPlaylistPlayer', ['updatePlayer', 'updatePlayerIsVisible']),
    eventLoadError(e) {
      this.debugLog(`eventLoadError: ${e.target.error.currentSrc}`);
    },
    closeOverlay() {
      this.appData.playlistIsVisible = false;
    },
    // two ways of passing the data out of the component: emit (to parent) or to store module (use either, but no need for both)
    broadcastPlayerData() {
      // alert(`broadcastPlayerData: ${JSON.stringify(this.appData)}`);
      this.updatePlayer(this.appData);
      // this.$emit('emittedPlayer', this.appData);
    },
    // (runs automatically after the audio file is loaded)
    eventCanPlay() {
      // update elapsed & remaining time and progress bar
      this.playerProgBarPosition();
      // update appData.currentTrack object for the "now playing" section
      // this.appData.currentTrack = this.appPlaylist[this.appData.currentTrackNum];
      // enable the Play/Pause button(s)
      // this.c_emptyPlaylist = false;
      // play the audio
      this.audioPlayer.play();
      // this.changeTrack(0);
      this.appData.playerIsPlaying = true;
      // update the UI so that Play button is shown (and Pause button is hidden)
      this.fp.wrapper.setAttribute('paused', false);
    },
    eventPlayEnded() {
      // if this is the last track of the playlist, reset player back to beginning of file
      if (this.appData.currentTrackNum === (this.c_totalTrackNum - 1)) {
        this.fp.wrapper.setAttribute('paused', true);
        this.audioPlayer.currentTime = 0;
      } else {
        // else, increment current tracknum and call function to load/play the file
        const nextTrackNum = this.appData.currentTrackNum + 1;
        this.changeTrack(nextTrackNum);
      }
    },
    eventTimeUpdate() {
      // update progress bar
      this.playerProgBarPosition();
    },

    checkDisabled(id) {
      let r = false;
      switch (id) {
        case 'playerBtnPrev':
        case 'playerBtnPlayPause':
        case 'playerBtnMuteUnmute':
          if (this.c_emptyPlaylist === true) {
            r = true;
          }
          break;
        case 'playerBtnNext':
          if (this.isPlayingLastTrack === true || this.c_totalTrackNum < 2) {
            r = true;
          }
          break;
        case 'playerShowPlaylistButton':
          if (this.c_emptyPlaylist) {
            r = true;
          }
          break;
        default:
          r = false;
          break;
      }
      return r;
    },

    // function to convert seconds into h:mm:ss
    secsToDur(secs) {
      const secnum = parseInt(secs, 10); // don't forget the second param
      let hours = '';
      let minutes = Math.floor((secnum - (hours * 3600)) / 60);
      let seconds = secnum - (hours * 3600) - (minutes * 60);

      if (secnum > 3600) {
        hours = `${Math.floor(secnum / 3600)}:`;
      }

      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      const r = `${hours}${minutes}:${seconds}`;
      return r;
    },

    // onclick: the user clicked inside the progress bar to skip to another location within the file
    playerSkip(e) {
      // calculate where in the bar did the user click (0% to 100%)
      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const w = this.fp.barShell.clientWidth;
      const perc = parseInt((x / w) * 100, 10) + 1;
      // update the bar
      this.fp.bar.style.width = `${perc}%`;
      // apply change to audio_tracks
      const dur = this.audioPlayer.duration;
      const secs = dur * (perc / 100);
      this.audioPlayer.currentTime = secs;
    },

    // calculate the percentage of the track played and updates the progress bar
    // also updates elapsed time and remaining time
    playerProgBarPosition() {
      const dur = this.audioPlayer.duration;
      const cur = this.audioPlayer.currentTime;

      // update elapsed & remaining time
      const elapsed = this.secsToDur(cur);
      this.fp.elapsed.innerText = elapsed;
      const remaining = this.secsToDur(dur - cur);
      this.fp.remaining.innerText = `-${remaining}`;

      const perc = parseInt((cur / dur) * 100, 10);
      this.fp.bar.style.width = `${perc}%`;
    },

    // various actions from the player controls
    playerCtrl(which) {
      const daPlayer = this.audioPlayer;
      let num = null;
      // alert(this.$store.getters.appData.currentTrackNum + '\n' + this.fogAudioFile);
      switch (which) {
        // user pressed the play or pause button (toggle); do action and toggle which button is visible
        case 'play':
          if (daPlayer.paused === true) {
            daPlayer.play();
          } else {
            daPlayer.pause();
          }
          this.fp.wrapper.setAttribute('paused', daPlayer.paused);
          this.appData.playerIsPlaying = !this.c_playerIsPlaying;
          break;

          // user pressed the mute or unmute button (toggle); do action and toggle which button is visible
        case 'mute':
          if (daPlayer.muted === true) {
            daPlayer.muted = false;
          } else {
            daPlayer.muted = true;
          }
          this.fp.wrapper.setAttribute('muted', daPlayer.muted);
          this.appData.playerIsMuted = daPlayer.muted;
          break;

        // user pressed the Next button
        case 'next':
          num = this.appData.currentTrackNum + 1;
          this.changeTrack(num);
          break;

        // user pressed the Previous button
        // if less than a second into the track, skip to previous track; else, move to start of this track
        case 'prev':
          if (parseInt(this.audioPlayer.currentTime, 10) === 0) {
            num = this.appData.currentTrackNum - 1;
            this.changeTrack(num);
          } else {
            daPlayer.currentTime = 0;
          }
          break;
        default:
          break;
      }
    },
    // if the num passed is in the array (0 to N-1)...
    // set the appData.currentTrackNum, update the src for the <audio> element and load the file
    changeTrack(num) {
      this.$nextTick(() => {
        if (num >= 0 && num < (this.c_totalTrackNum)) {
          this.appData.currentTrackNum = num;
          this.appData.currentTrack = this.appPlaylist[num];

          this.audioPlayer.currentTime = 0;
          this.audioPlayer.pause();
          this.audioPlayer.currentTime = 0;
          this.audioPlayer.src = 'data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEAVFYAAFRWAAABAAgAZGF0YQAAAAA=';
          this.audioPlayer.setAttribute('src', this.appPlaylist[num].file);
          this.audioPlayer.load();
        } else {
          this.debugLog(`changeTrack: out of range === ${this.c_totalTrackNum} (less than ${(this.c_totalTrackNum)})`);
        }
        this.closeOverlay();
      });
    },

    audioStop() {
      this.audioPlayer.pause();
      this.audioPlayer.currentTime = 0;
      this.audioPlayer.src = 'data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEAVFYAAFRWAAABAAgAZGF0YQAAAAA=';
    },

    // prefixes the image file with the path to the asset
    imageSource(f) {
      return `${this.$images}/cd_images/${f}`;
    },

    // prefixes the audio file with the path to the asset
    audioSource(f) {
      let src = false;
      try {
        src = `${this.$audio}/mp3/${f}.mp3`;
      } catch {
        this.debugLog(`audioSource 3 catch\n\n${f}`);
      }
      return src;
    },

    showHidePlaylist() {
      this.appData.playlistIsVisible = !this.c_playlistIsVisible;
    },

    loadNewPlaylist(p) {
      const newp = [];
      Object.entries(p).forEach((arr, i) => {
        newp[i] = {};
        newp[i].title = p[i].title;
        newp[i].artist = p[i].artist;
        newp[i].album = p[i].album;
        newp[i].duration = p[i].duration;
        newp[i].filename = p[i].file;
        newp[i].file = this.audioSource(p[i].file);
        newp[i].image = this.imageSource(p[i].image);
      });

      this.appPlaylist = newp;
      this.appData.totalTrackNum = this.appPlaylist.length;

      this.changeTrack(0);

      if (this.storePlayerIsVisible === false) {
        this.updatePlayerIsVisible(true);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

  #listOverlay {
    position: fixed;
    top:0;
    left: 0;
    height: 100vh;
    width: 100vh;
    z-index: 5;
  }

  #audioPlayerWrapper {
    .playbackButtons {
      display: flex;

      button {
        height: 2.8rem;
        width: 2.8rem;
        padding: .2rem;
        margin-right: 1rem;
        font-size: 2rem;
        border-radius: 0.4rem;
        -webkit-appearance: none;

        &[disabled="disabled"],
        &[disabled] {
          opacity: 0.3;
        }

        img {
          height: 2rem;
          width: 2rem;
        }
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      display: relative;

      .button-icon-mask {
        div {
          background-color: black;
          height: 2rem;
          width: 2rem;
        }
      }
    }

    .button-icon-mask {
      &.audio-button-prev {
        mask-image: url('./icons/Prev.svg');
      }

      &.audio-button-play {
        mask-image: url('./icons/Play.svg');
      }

      &.audio-button-pause {
        mask-image: url('./icons/Pause.svg');
      }

      &.audio-button-next {
        mask-image: url('./icons/Next.svg');
      }

      &.audio-button-not-muted {
        mask-image: url('./icons/NotMuted.svg');
      }

      &.audio-button-muted {
        mask-image: url('./icons/Muted.svg');
      }
    }

    #playerBtnPause {
      display: none;
    }
    #playerBtnUnmute {
      display: none;
    }
    &[paused=false] {
      #playerBtnPause {
        display: inline-block;
      }
      #playerBtnPlay {
        display: none;
      }
    }
    &[muted=true] {
      #playerBtnUnmute {
        display: inline-block;
      }
      #playerBtnMute {
        display: none;
      }
    }

    #fpConsole {
      display: flex;

      &.hidden {
        display: none;
      }

      .showPlaylistButton {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 1rem;
      }

      #currentlyPlaying {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;

        .art {
          display: flex;
          justify-content: center;

          img {
            margin: 1rem;
            height: 10rem;

            @media screen and (max-width: 1000px) {
              height: 5rem;
            }
          }
        }
        .trackInfo {
          width: 50rem;

          div {
            text-align: center;
          }

          #currentTrack
          #currentArtist {
            padding: .5rem;
          }
        }
        #progressBarDiv {
          align-items: center;

          #playerTrackElapsed,
          #playerTrackRemain {
            padding: 0 1rem;
          }

          #progressBar {
            height: 10px;
            flex: 1;
            justify-content: flex-start;
            padding: 0;
            appearance: none;

            #progressBarBar {
              height: 100%;
              width: 0%;
              text-align: left;
            }
          }
        }

        .mobilePB {
          .art,
          .showPlaylistButton {
            display: none;
          }
        }
      }
    }
    .fogPlaylistSleeve {
      text-align: center;

      #fogPlaylist {
        position: absolute;
        display: inline-block;
        box-shadow: 0 10px 10px rgba(0,0,0,.5);
        z-index: 10;
        right: 0;
        min-width: 50%;
        max-width: 80%;
        max-height: 70vh;
        overflow-y: auto;

        &.hidden {
          display: none;
        }

        table {
          width: 100%;
        }

        tr {
          td {
            padding: 1rem 0.5rem;
            text-align: left;
            line-height: 1.2;

            .playlist-row-1 {
              padding-bottom: 0.8rem;
            }
            .playlist-row-2 {
              padding-left: 0.8rem;
            }
          }

          td.duration {
            padding-left: 1.6rem;
            text-align: right;
          }
        }

        /*
        td.mobile {
          display: none;
        }
        */

        @media screen and (max-width: 1000px) {
          td.mobile {
            display: block;
          }
          td.desktop {
            display: none;
          }
        }
      }

      @media screen and (max-width: 1000px) {
        #fogPlaylist {
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    #audioPlayerWrapper {
      #fpConsole {
        #currentlyPlaying {
          display: block;

          .trackInfo {
            width: 100%;
            margin-top: 1rem;
          }

          .art {
            display: none;
          }

          .mobilePB {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .art,
            .showPlaylistButton {
              display: block;
            }
          }
        }

        .showPlaylistButton {
          display: none;
        }
      }
    }
  }
</style>
