<template>
  <button
    class="icon-and-label-button"
    @click="loadJson(playlist)"
    :aria-label="label"
  >
    <span class="foggy-icon play-all-audio"/>
    <span class="label">
      {{ label }}
    </span>
  </button>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    playlist: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions('audioPlaylistPlayer', ['updatePlaylistJSON']),
    loadJson(json) {
      this.updatePlaylistJSON(json);
    },
  },
};
</script>

<style lang="scss">
/*
[{"file":"DTC0404_LRC_katy","title":"Katy Jam","artist":"Living Room Combo","album":"Let's Roll One!","duration":"4:55","image":"dtc04.png"},{"file":"DTC0511_IQB_katy","title":"Katy","artist":"I.Q. Bonehead","album":"Barely Almost","duration":"4:45","image":"dtc05.png"}]

[{file:"DTC0404_LRC_katy",title:"Katy Jam",artist:"Living Room Combo",album:"Let's Roll One!",duration:"4:55",image:"dtc04.png"}]

[object Object],[object Object]
*/
</style>
