<template>
  <div class="section-table">
    <section-header
      :label="label"
    />
    <div class="section-body">
      <div class="section-body-sleeve">
        <table-html
          :playlist="playlist"
          :rows="rows"
          :dl="dl"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from './HeaderSection.vue';
import TableHtml from './TableHtml.vue';

export default {
  components: {
    SectionHeader,
    TableHtml,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    rows: {
      type: Array,
      default: () => [],
    },
    dl: {
      type: String,
      default: '',
    },
    playlist: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
  },
};
</script>

<style lang="scss">

</style>
