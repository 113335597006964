<template>
  <div
    class="section-html"
  >
    <section-header
      v-if="label !== null"
      :label="label"
    />
    <div
      v-if="html !== null && html !== ''"
      class="section-body"
    >
      <div
        v-html="html"
        class="section-body-sleeve"
      />
    </div>
  </div>
</template>

<script>
import SectionHeader from './HeaderSection.vue';

export default {
  components: {
    SectionHeader,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    html: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">

</style>
